import APIService from './APIService'
import { Flow, FlowWithElements } from '../models/flowModel' // Adjust the import path as necessary
import { Imprint } from '../models/imprint'
import { CustomerIdentityDataModel } from '../models/customerData'

export class FlowService {
    public async getFlows(userId: string): Promise<Flow[] | undefined> {
        try {
            return await APIService.jsonRequest<Flow[]>(
                '/flows/user/' + userId,
                'GET',
            )
        } catch (exception) {
            return []
        }
    }

    public async getFlowById(
        id: string,
        dataId: string | null = null,
    ): Promise<Flow | undefined> {
        if (id === undefined || id === '') {
            return Promise.resolve({
                name: 'None',
                description: 'No flow loaded',
                id: '',
                style: {},
                user: '',
                parameters: { isPublic: false },
            })
        }
        if (dataId !== null) {
            return APIService.jsonRequest(`/flows/${id}?data=${dataId}`, 'GET')
        }
        return APIService.jsonRequest(`/flows/${id}`, 'GET')
    }

    public async createFlow(flowData: Flow): Promise<Flow | undefined> {
        return APIService.jsonRequest('/flows', 'POST', flowData)
    }

    public async updateFlow(
        id: string,
        flowData: Flow,
    ): Promise<Flow | undefined> {
        return APIService.jsonRequest(`/flows`, 'PUT', flowData)
    }

    public async deleteFlow(id: string): Promise<void> {
        await APIService.jsonRequest(`/flows/${id}`, 'DELETE', null, false)
    }

    public async exportCompleteFlow(
        id: string,
    ): Promise<FlowWithElements | undefined> {
        return APIService.jsonRequest(`/flows/export/${id}`, 'GET')
    }

    public async importCompleteFlow(
        flow: FlowWithElements,
    ): Promise<string | undefined> {
        return APIService.jsonRequest(`/flows/import`, 'POST', flow)
    }

    public async getFlowImprint(id: string): Promise<Imprint | undefined> {
        return APIService.jsonRequest(`/flows/imprint/${id}`, 'GET')
    }

    public async getStyleByFlowId(
        id: string,
    ): Promise<CustomerIdentityDataModel | undefined> {
        return APIService.jsonRequest(`/flow/style/${id}`, 'GET')
    }
}
